import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { UnControlled as CodeMirror } from "react-codemirror2";

import Collapse from "rc-collapse";

import SEO from "../components/seo";
import Layout from "../components/layout";

// Why don't these imports work? See gatsby-browser.js
// import "codemirror/lib/codemirror.css";
// import "codemirror/theme/lucario.css";
// import "codemirror/mode/javascript/javascript";
// import "codemirror/mode/yaml/yaml";

import "../styles/api-doc.css";

const getAnchorName = (title) => {
  return title.split(" ").join("-").toLowerCase();
};

const ApiDocumentation = (props) => {
  const [activeKeys, setActiveKeys] = React.useState([]);

  const handleCollapseChange = (key, fromTable) => {
    let tempArray = [...activeKeys];
    const indexInAction = tempArray.indexOf(key);
    if (indexInAction > -1) {
      if (fromTable) return;
      else tempArray.splice(indexInAction, 1);
    } else tempArray.push(key);
    setActiveKeys([...tempArray]);
  };

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/api_documentaion/" } }
      ) {
        nodes {
          frontmatter {
            title
            paragraph1 {
              title
              contact_link_text
              contact_link_to
              features {
                feature
              }
            }
            paragraph2 {
              title
              description
            }
            base_url
            api_info {
              title
              endpoints {
                title
                description
                type
                headers {
                  title
                  code {
                    code
                    lang
                  }
                }
                request {
                  title
                  code {
                    code
                    lang
                  }
                }
                response {
                  title
                  code {
                    code
                    lang
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const title = data.allMarkdownRemark.nodes[0].frontmatter.title;
  const paragraph1 = data.allMarkdownRemark.nodes[0].frontmatter.paragraph1;
  const paragraph2 = data.allMarkdownRemark.nodes[0].frontmatter.paragraph2;
  const base_url = data.allMarkdownRemark.nodes[0].frontmatter.base_url;
  const api_info = data.allMarkdownRemark.nodes[0].frontmatter.api_info;

  const codeMirrorSharedProps = {
    theme: "lucario",
    lineNumbers: true,
    readOnly: true,
  };

  return (
    <>
      <SEO title="Data API Documentation" />
      <Layout>
        <main
          id="top"
          className="max-w-container flex flex-col space-y-10 py-12 px-4 md:px-10 md:py-20 mx-auto"
        >
          <h2 className="max-w-paragraph-heading">{title}</h2>

          <div>
            {/* <div className="font-bold text-xl mb-4">Table of Contents</div> */}
            <ul className="list-disc ml-8">
              <li className="text-lg text-gray font-bold mb-2">Introduction</li>
              <ul className="ml-4 mb-2">
                <li className="w-max max-w-full text-blue md:text-lg text-black font-medium cursor-pointer mb-2">
                  <Link
                    to={`${
                      props.location.pathname +
                      "#" +
                      getAnchorName(paragraph1.title)
                    }`}
                  >
                    {paragraph1.title}
                  </Link>
                </li>
                <li className="w-max max-w-full text-blue md:text-lg text-black font-medium cursor-pointer mb-2">
                  <Link
                    to={`${
                      props.location.pathname +
                      "#" +
                      getAnchorName(paragraph2.title)
                    }`}
                  >
                    {paragraph2.title}
                  </Link>
                </li>
              </ul>
              <li className="text-lg text-gray font-bold mb-2">Endpoints</li>
              <ul className="ml-4 mb-2">
                {api_info.map((api, index) => (
                  <>
                    <li className="w-max max-w-full	text-blue md:text-lg text-black font-bold cursor-pointer mb-2">
                      <Link
                        to={`${
                          props.location.pathname +
                          "#" +
                          getAnchorName(api.title)
                        }`}
                      >
                        {api.title}
                      </Link>
                    </li>
                    <ul className="ml-4 mb-2">
                      {api.endpoints.map((endpoint, index) => (
                        <li className="w-max max-w-full	text-blue md:text-lg text-black font-medium cursor-pointer mb-2">
                          <Link
                            to={`${
                              props.location.pathname +
                              "#" +
                              getAnchorName(endpoint.title)
                            }`}
                          >
                            {endpoint.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ))}
              </ul>
            </ul>
          </div>

          <hr className="opacity-50 text-gray" />

          <div id={getAnchorName(paragraph1.title)}>
            <div className="font-bold text-xl mb-4">{paragraph1.title}</div>
            <ul className="list-disc ml-8">
              {paragraph1.features.map((feature) => (
                <li className="text-lg md:text-xl">{feature.feature}</li>
              ))}
            </ul>
            <div className="mt-4">
              <a
                href={paragraph1.contact_link_to}
                className="font-bold text-xl text-blue"
              >
                {paragraph1.contact_link_text}
              </a>
            </div>
          </div>
          <div>
            <div
              id={getAnchorName(paragraph2.title)}
              className="font-bold text-xl mb-4"
            >
              {paragraph2.title}
            </div>
            <p className="text-gray text-lg md:text-xl">
              {paragraph2.description}
            </p>
          </div>

          <div>
            <div id={getAnchorName(base_url)} className="font-bold text-xl">
              Base URL:{" "}
              <span className="font-mono font-medium ml-2 text-blue text-lg">
                {base_url}
              </span>
            </div>
            {api_info.map((api, api_index) => {
              return (
                <div id={getAnchorName(api.title)}>
                  <div className="font-bold text-xl mt-8 mb-4">{api.title}</div>
                  {api.endpoints.map((endpoint, end_index) => {
                    return (
                      <div className="mt-4 mb-4">
                        <Collapse
                          accordion={false}
                          activeKey={activeKeys}
                          destroyInactivePanel={true}
                          onChange={() =>
                            handleCollapseChange(
                              String(api_index) + String(end_index)
                            )
                          }
                        >
                          <Collapse.Panel
                            key={String(api_index) + String(end_index)}
                            header={
                              <div
                                id={getAnchorName(endpoint.title)}
                                className="font-mono w-full flex justify-between items-center"
                              >
                                <span
                                  className={`${
                                    endpoint.type.toLowerCase() === "post"
                                      ? "bg-teal"
                                      : endpoint.type.toLowerCase() === "get"
                                      ? "bg-blue"
                                      : "bg-gray"
                                  } text-white font-bold font-mono px-4 py-1 rounded-md w-24 text-center mr-2`}
                                >
                                  {endpoint.type}
                                </span>
                                <span>{endpoint.title}</span>
                              </div>
                            }
                            showArrow
                            headerClass={`font-medium text-md text-dark-gray mb-0 flex items-center cursor-pointer`}
                            className={`flex flex-col border rounded-md ${
                              endpoint.type.toLowerCase() === "post"
                                ? "border-teal"
                                : endpoint.type.toLowerCase() === "get"
                                ? "border-blue"
                                : "border-gray"
                            } pl-1 pr-1 py-1 ${
                              endpoint.type.toLowerCase() === "post"
                                ? "bg-faded-teal"
                                : endpoint.type.toLowerCase() === "get"
                                ? "bg-faded-blue"
                                : "bg-faded-gray"
                            }`}
                          >
                            <p className="text-gray text-base md:text-lg mt-4 px-2">
                              {endpoint.description}
                            </p>
                            <div className="px-0 md:px-2 pt-6 pb-2">
                              <div className="font-bold text-lg mb-2">
                                {endpoint.headers.title}
                              </div>
                              <div className="mb-4">
                                <CodeMirror
                                  value={endpoint.headers.code.code}
                                  options={{
                                    mode: endpoint.headers.code.lang,
                                    ...codeMirrorSharedProps,
                                  }}
                                  className="CodeMirror-small"
                                />
                              </div>
                              <div className="font-bold text-lg mb-2">
                                {endpoint.request.title}
                              </div>
                              <div className="mb-4">
                                <CodeMirror
                                  value={endpoint.request.code.code}
                                  options={{
                                    mode: endpoint.request.code.lang,
                                    ...codeMirrorSharedProps,
                                  }}
                                />
                              </div>
                              <div className="font-bold text-lg mb-2">
                                {endpoint.response.title}
                              </div>
                              <CodeMirror
                                value={endpoint.response.code.code}
                                options={{
                                  mode: endpoint.response.code.lang,
                                  ...codeMirrorSharedProps,
                                }}
                              />
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </main>
      </Layout>
    </>
  );
};

export default ApiDocumentation;
